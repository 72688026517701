<style lang="less" scoped>
  .base-card {
    position: relative;
    background-color: #FFF;
    padding: 20px;
    .title {
      font-size: 16px;
      color: #000;
      margin-bottom: 15px;
    }
    & + &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      background-color: #E9EFF4;
      width: 30%;
    }
  }
</style>

<template>
  <div class="base-card">
    <div class="title">{{title}}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' }
  }
}
</script>
