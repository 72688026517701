<style scoped lang="less">
  .sys-urls-item {
    padding: 5px;
    box-sizing: border-box;
    position: relative;
  }
  .wrap {
    padding: 10px;
    cursor: pointer;
    position: relative;
    background-color: #FFF;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    transition: all .3s;
    &:hover {
      box-shadow: 0 2px 5px 0 rgba(47, 91, 234, .3);
    }
  }
  .status {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    right: 5px;
    top: 5px;
    position: absolute;
  }
  .cover {
    height: 40px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .name {
    margin-top: 10px;
    font-size: 12px;
  }
  .type {
    font-size: 12px;
    color: rgba(50, 60, 71, 0.5);
  }
  .copy {
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0;
  }
  .sys-urls-item:hover {
    .copy {
      opacity: 1;
    }
  }
</style>

<template>
  <div class="sys-urls-item">
    <div class="wrap" @click="land">
      <div class="status" :style="{backgroundColor: statusColor}"></div>
      <div class="cover" :style="{
        backgroundImage: 'url(' + logo + ')'
      }"></div>
      <div class="name">{{item ? (item.sysName + '/' + (item.useOrgName || item.user)) : '暂无'}}</div>
      <div class="type">{{item ? item.type : '暂无'}}</div>
    </div>
    <text-copy :text="this.item.url" class="copy"/>
  </div>
</template>

<script>
import TextCopy from '../../views/home/TextCopy.vue'
export default {
  components: { TextCopy },
  props: {
    item: { type: Object, default: null}
  },
  computed: {
    logo () {
      return '/static/logo/logo.png'
    },
    statusColor () {
      switch (this.item.status || '') {
        case '正常':
          return '#19be6b'
        case '异常':
          return '#ed4014'
        default:
          return 'transparent'
      }
    }
  },
  methods: {
    land () {
      window.open(this.item.url)
    }
  }
}
</script>
