<style scoped lang="less">
  .service-block {
    display: flex;
    flex-wrap: wrap;
    width: 380px;
    & > .service-item {
      &:first-of-type ~ & {
        border-left: 1px solid #323C47;
      }
    }
  }
</style>

<template>
  <div class="service-block">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>
