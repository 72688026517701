<style lang="less" scope>
  .copy-text {
    z-index: 1;
    opacity: 1;
    transition: all .3s;
    &:hover {
      transform: scale(1.2);
    }
    &:active {
      transform: scale(1);
    }
  }
</style>

<template>
  <i class="copy-text iconfont icon-copy" @click="copy"></i>
</template>

<script>
export default {
  props: {
    text: { type: String, default: '' }
  },
  methods: {
    copy () {
      if (this.text) {
        navigator.clipboard.writeText(this.text).then(()  => {
          this.$notice.success('复制成功', this.text)
        }, (e) =>  {
          this.$notice.warning('复制失败' + e.message)
        });
      }
    }
  }
}
</script>
