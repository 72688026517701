<style scoped lang="less">
  .service-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
    line-height: 20px;
    span {
      cursor: pointer;
      font-size: 12px;
    }
    .copy {
      margin-left: 0px;
      cursor: pointer;
      opacity: 0;
    }
    &:hover {
      .copy {
        opacity: 1;
      }
    }
    &::after {
      content: '|';
      color: #323C47;
      margin-right: 5px;
    }
    &:last-of-type::after {display: none;}
  }
</style>

<template>
  <div class="service-item" v-if="item.description">
    <span @click="land">{{item ? item.description : ''}}</span>
    <text-copy :text="item.url" class="copy"/>
  </div>
</template>

<script>
import TextCopy from '../../views/home/TextCopy.vue'
export default {
  components: { TextCopy },
  props: {
    item: { type: Object, default: null}
  },
  computed: {
    statusColor () {
      switch (this.item.status || '') {
        case '正常':
          return '#19be6b'
        case '异常':
          return '#ed4014'
        default:
          return 'transparent'
      }
    }
  },
  methods: {
    land () {
      window.open(this.item.url)
    }
  }
}
</script>
