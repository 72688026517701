<style lang="less" scoped>
  .home-page {
    display: flex;
    padding: 10px;
    height: 100%;
    overflow: hidden;
    align-items: flex-start;
    box-sizing: border-box;
  }
  .common-list {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    height: 0;
    .list-item {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      .item-wrap {
        height: 0;
        flex: 1;
        overflow: auto;
      }
    }
  }
  .service-rb-block {
    margin-top: 10px;
    height: 250px;
    background-image: url('/static/images/bg-work.jpg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 326px 204px;
  }
  .sys-names {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    .sys-name-item {
      margin: 0 10px;
      cursor: pointer;
    }
    .sys-name-item-a {
      color: #2F5BEA;
    }
  }
  .service-tb-block {
    height: calc(100% - 340px);
  }
  .sys-u-list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
    height: calc(100% - 20px);
  }
  .left {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .right {
    height: 100%;
  }
</style>

<template>
  <div class="home-page">
    <!-- <div class="left">
      <account />
      <div class="common-list">
        <base-card title="我的系统" class="list-item">
          <sys-block class="item-wrap">
            <sys-item v-for="(sys, i) in mySysList" :item="sys" :key="i"/>
          </sys-block>
        </base-card>
        <base-card title="我的服务器" class="list-item">
          <server-block class="item-wrap">
            <server-item style="width: 50%" v-for="(server, i) in myServerList" :key="i" :item="server"></server-item>
          </server-block>
        </base-card>
      </div>
    </div> -->
    <div class="right" style="flex: 1;">
      <base-card title="系统导航" class="service-tb-block">
        <div class="sys-u-list">
          <sys-urls-item style="width: 180px;margin: 5px;" v-for="(sys, i) in sysListUrls.filter(v => chooseSysName === '全部' || v.sysName === chooseSysName)" :item="sys" :key="i"/>
        </div>
        <div class="sys-names">
          <div class="sys-name-item" :class="{'sys-name-item-a': chooseSysName === item}" @click="chooseSysName = item" v-for="item in sysNames" :key="item">{{item}}</div>
        </div>
      </base-card>
      <base-card title="服务导航" class="service-rb-block">
        <service-urls-block style="width: unset;">
          <service-urls-item v-for="(service, i) in serviceListUrls" :key="i" :item="service"></service-urls-item>
        </service-urls-block>
      </base-card>
    </div>
  </div>
</template>

<script>
import BaseCard from '@/components/base/BaseCard.vue';
// import SysItem from '@/components/base/SysItem.vue';
// import account from './account.vue';
// import SysBlock from '@/components/base/SysBlock.vue';
import ServiceUrlsBlock from '@/components/base/ServiceUrlsBlock.vue';
import ServiceUrlsItem from '@/components/base/ServiceUrlsItem.vue';
import SysUrlsItem from '@/components/base/SysUrlsItem.vue';
// import ServerBlock from '@/components/base/ServerBlock.vue';
// import ServerItem from '@/components/base/ServerItem.vue';

import { ucenterRequest } from '@/api'

export default {
  // components: { account, BaseCard, SysItem, SysBlock, ServerBlock, ServerItem, ServiceUrlsBlock, ServiceUrlsItem, SysUrlsItem },
  components: { BaseCard, ServiceUrlsBlock, ServiceUrlsItem, SysUrlsItem },
  data () {
    return {
      sysListUrls: [],
      sysNames: [],
      chooseSysName: '全部',
      serviceListUrls: [],
      sysList: [],
      serverList: [],
      allAuthorities: [],
    }
  },
  methods: {},
  computed: {
    mySysList () {
      let ids = this.currentAuthorities.filter(v => v.objType === 'sys').map(v => v.objId)
      console.log(this.sysList, ids)
      return this.sysList.filter(v => {
        return ids.includes(v.key)
      })
    },
    myServerList () {
      let ids = this.currentAuthorities.filter(v => v.objType === 'server').map(v => v.objId)
      return this.serverList.filter(v => {
        return ids.includes(v.key)
      })
    },
    currentUserId () {
      return this.$store.getters.userInfo ? this.$store.getters.userInfo.id : null
    },
    currentAuthorities () {
      let auth = this.allAuthorities.find(auth => auth.id === this.currentUserId)
      return auth ? auth.authorities : []
    }
  },
  async mounted() {
    // 并行加载
    this.$store.dispatch('loadSysUrls').then(v => {
      this.sysNames = ['全部']
      this.sysListUrls = v
      v.forEach(v1 => !this.sysNames.includes(v1.sysName) ? this.sysNames.push(v1.sysName) : null)
    })
    this.$store.dispatch('loadServiceUrls').then(v => this.serviceListUrls = v)
    this.$store.dispatch('loadSysList').then((v) => {
      this.sysList = v
    })
    this.$store.dispatch('loadServerList').then(v => this.serverList = v)
    ucenterRequest.getAuthorities().then(v => this.allAuthorities = v)
  }
};
</script>